/* eslint-disable camelcase */
import moment from "moment";
import PropTypes from "prop-types";
import { useState, useRef, useEffect, useCallback } from "react";
import LABEL from "constants/label";
import TaxonomySelector from "components/TaxonomySelector";
import { Modal, Button, Grid, Label, Icon, Segment, Dimmer, Loader } from "semantic-ui-react";
import Vignettes from "pages/Cases/Vignettes";
import ConfirmationModal from "components/common/Modal/ConfirmationModal/ConfirmationModal";
import { toastError, toastSuccess, checkScroll } from "helpers/utils";
import CONFIG from "constants/config";
import CaseTitle from "components/common/Case/CaseTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCaseTopics,
  updateCaseSkills,
  deleteCaseTopicsAndSkills,
  resetCaseTopicsAndSkillsStatus
} from "redux/actions/cases";
import CopyText from "components/common/CopyText";
import PipedList from "components/common/PipedList";
import { Description, Team, Icon as IconS } from "components/common";
import { cases as caseConstants } from "redux/constants";
import "./styles.scss";

const { COMMON, CASES, AFFILIATIONS_PAGE, EDIT_CASES } = LABEL;
const { STATUS } = caseConstants;

/**
 * Edit About
 *
 * @param  {object} profile user profile object
 * @param  {object} permissions user permissions for Edit About popup
 * @param  {bool} open popup open/close state
 * @param  {func} onClose function fires on closing of popup
 * @param  {func} onOpen function fires on opening of popup
 *
 * @return Edit About component
 */

const ViewCaseDetails = ({
  isEditable,
  caseObj,
  open,
  localDispatch,
  pageNumber,
  onClose,
  onOpen,
  sortBy,
  rank,
  caseExpertise = [],
  caseSkills = []
}) => {
  const {
    crawl_item_id,
    case_number,
    all_case_team,
    case_type,
    date_opened_actual,
    case_short_desc,
    textDescription,
    client_desc,
    casevignettesstatus,
    office_name,
    casevignettes,
    client_name,
    industrypas,
    functionalpas,
    case_full_desc,
    totalCaseHours
  } = caseObj;

  const dispatch = useDispatch();
  const { apiUrl, xApiKey } = CONFIG;
  const targetRefTopic = useRef();
  const targetRefSkill = useRef();
  const { id } = useSelector((state) => state?.peopleData);
  const { updateCaseTopicsStatus, updateCaseSkillsStatus, deleteCaseTopicsSkillsStatus } = useSelector(
    (state) => state
  );

  const vignettesCount = casevignettes?.length || 0;
  const tagIndustry = industrypas?.map((value) => value.topic_name_alias);
  const tagFunctional = functionalpas?.map((value) => value?.topic_name_alias);
  const tags = [...(tagIndustry ?? []), ...(tagFunctional ?? [])];

  const [saveLoading, setSaveLoading] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const [isdirty, setIsDirty] = useState(false);
  const [deletedTopics, setDeletedTopics] = useState([]);
  const [newlyAddedTopics, setNewlyAddedTopics] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [newlyAddedSkills, setNewlyAddedSkills] = useState([]);
  const [deletedSkills, setDeletedSkills] = useState([]);
  const [openWarnPopup, setOpenWarnPopup] = useState(false);

  useEffect(() => {
    if (caseExpertise.length === 0 && !isEditable) {
      setTopicList(() => {
        return [{ name: "--" }];
      });
    }
    if (caseExpertise.length > 0) {
      setTopicList([...caseExpertise]);
    }
  }, [caseExpertise]);

  useEffect(() => {
    if (caseSkills.length === 0 && !isEditable) {
      setSkillList(() => {
        return [{ name: "--" }];
      });
    }
    if (caseSkills.length > 0) {
      setSkillList([...caseSkills]);
    }
  }, [caseSkills]);

  // get Total case hours from cases object
  const caseHrs = totalCaseHours;

  const handleSave = async () => {
    setSaveLoading(true);

    if (newlyAddedTopics.length > 0) {
      const expertiseGuid = newlyAddedTopics.reduce((acc, curr) => [...acc, curr?.id], []);
      try {
        dispatch(
          updateCaseTopics({
            endpoint: `${apiUrl.linkApi}profile/${id}/caseexpertise`,
            data: [
              {
                caseGUID: crawl_item_id,
                caseNumber: "",
                expertiseGuid
              }
            ],
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
      } catch (e) {
        setSaveLoading(false);

        toastError();
      }
    }
    if (newlyAddedSkills.length > 0) {
      const skillGuid = newlyAddedSkills.reduce((acc, curr) => [...acc, curr?.id], []);
      try {
        dispatch(
          updateCaseSkills({
            endpoint: `${apiUrl.linkApi}profile/${id}/caseskill`,
            data: [
              {
                caseGUID: crawl_item_id,
                caseNumber: "",
                skillGuid
              }
            ],
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
      } catch (e) {
        setSaveLoading(false);

        toastError();
      }
    }
    if (deletedTopics.length > 0 || deletedSkills.length > 0) {
      try {
        dispatch(
          deleteCaseTopicsAndSkills({
            endpoint: `${apiUrl.linkApi}profile/update/${id}/casedetails`,
            data: {
              caseGuid: crawl_item_id,
              skillExpertiseToBeDeleted: deletedSkills,
              caseExpertiseToBeDeleted: deletedTopics
            },
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
      } catch (e) {
        setSaveLoading(false);

        toastError();
      }
    }
  };

  // check id content in Topic/Skill box is scrollable

  const [topicScrollable, setTopicScrollable] = useState(false);
  const [skillScrollable, setSkillScrollable] = useState(false);

  // fire check scroll function when topic/skill added/deleted or on card load

  useEffect(() => {
    checkScroll(targetRefTopic, setTopicScrollable);
    checkScroll(targetRefSkill, setSkillScrollable);
  }, [topicList, skillList]);

  const scroll = (e, dir) => {
    const el = e.currentTarget.parentElement.parentElement.children[0];

    if (dir === "UP") {
      el.scrollTo({ top: el.scrollTop - 30, behavior: "smooth" });
    } else {
      el.scrollTo({ top: el.scrollTop + 30, behavior: "smooth" });
    }
  };

  // This is to reset the isDirty flag.
  useEffect(() => {
    if (
      newlyAddedSkills.length <= 0 &&
      newlyAddedTopics.length <= 0 &&
      deletedSkills.length <= 0 &&
      deletedTopics.length <= 0
    )
      setIsDirty(false);
  }, [newlyAddedTopics, deletedTopics]);

  const handleSaveWrapUp = () => {
    setSaveLoading(false);
    dispatch(resetCaseTopicsAndSkillsStatus());
  };

  useEffect(() => {
    if (
      updateCaseTopicsStatus !== STATUS.SAVING &&
      deleteCaseTopicsSkillsStatus !== STATUS.DELETING &&
      updateCaseSkillsStatus !== STATUS.SAVING
    ) {
      setIsDirty(false);
      if (
        updateCaseTopicsStatus === STATUS.SAVE_FAILURE ||
        updateCaseSkillsStatus === STATUS.SAVE_FAILURE ||
        deleteCaseTopicsSkillsStatus === STATUS.DELETE_FAILURE
      ) {
        toastError();
        handleSaveWrapUp();
      }
      if (updateCaseSkillsStatus === STATUS.SAVE_SUCCESS) {
        const action = {
          type: caseConstants.RESET_CASE_SKILLS,
          data: { caseGuid: crawl_item_id, skillList, pageNumber }
        };
        localDispatch(action);
      }
      if (updateCaseTopicsStatus === STATUS.SAVE_SUCCESS) {
        const action = {
          type: caseConstants.RESET_CASE_TOPICS,
          data: { caseGuid: crawl_item_id, topicList, pageNumber }
        };
        localDispatch(action);
      }
      if (deleteCaseTopicsSkillsStatus === STATUS.DELETE_SUCCESS) {
        const updateTopicsAction = {
          type: caseConstants.RESET_CASE_TOPICS,
          data: { caseGuid: crawl_item_id, topicList, pageNumber }
        };
        const updateSkillsAction = {
          type: caseConstants.RESET_CASE_SKILLS,
          data: { caseGuid: crawl_item_id, skillList, pageNumber }
        };
        localDispatch(updateTopicsAction);
        localDispatch(updateSkillsAction);
      }
      if (
        updateCaseSkillsStatus === STATUS.SAVE_SUCCESS ||
        updateCaseTopicsStatus === STATUS.SAVE_SUCCESS ||
        deleteCaseTopicsSkillsStatus === STATUS.DELETE_SUCCESS
      ) {
        toastSuccess();
        handleSaveWrapUp();
        onClose();
      }
    }
  }, [updateCaseSkillsStatus, updateCaseTopicsStatus, deleteCaseTopicsSkillsStatus]);

  // logic to handle addition of new skill
  const handleAddSkill = (newSkill) => {
    setIsDirty(true);
    setNewlyAddedSkills((prev) => [...prev, { id: newSkill?.id, name: newSkill?.name }]);
    setSkillList((prev) => [{ id: newSkill?.id, name: newSkill?.name }, ...prev]);
  };

  // logic to handle addition of new expertise
  const handleAddTopic = (newTopic) => {
    setIsDirty(true);
    setNewlyAddedTopics((prev) => [...prev, { id: newTopic?.id, name: newTopic?.name }]);
    setTopicList((prev) => [{ id: newTopic?.id, name: newTopic?.name }, ...prev]);
  };

  // logic to handle deletion of expertise
  const handleDeleteTopic = (topicId) => {
    if (newlyAddedTopics.findIndex((topic) => topic?.id === topicId) !== -1) {
      setNewlyAddedTopics(newlyAddedTopics.filter((topic) => topic?.id !== topicId));
      setTopicList(topicList.filter((topic) => topic?.id !== topicId));
    } else {
      setIsDirty(true);
      setTopicList(topicList.filter((topic) => topic?.id !== topicId));
      setDeletedTopics((prev) => [...prev, topicId]);
    }
  };

  // logic to handle deletion of skill
  const handleDeleteSkill = (skillId) => {
    if (newlyAddedSkills.findIndex((skill) => skill?.id === skillId) !== -1) {
      setNewlyAddedSkills(newlyAddedSkills.filter((skill) => skill?.id !== skillId));
      setSkillList(skillList.filter((skill) => skill?.id !== skillId));
    } else {
      setIsDirty(true);
      setSkillList(skillList.filter((skill) => skill?.id !== skillId));
      setDeletedSkills((prev) => [...prev, skillId]);
    }
  };

  const checkDirty = () => {
    if (isdirty === true) {
      setOpenWarnPopup(true);
    } else {
      onClose();
    }
  };

  const TopicTaxonomy = useCallback(
    () => (
      <TaxonomySelector
        placeholder={AFFILIATIONS_PAGE.SearchExpertise}
        endpoint={apiUrl.smartLogicApi}
        tbdb={AFFILIATIONS_PAGE.BusinessAndSubject}
        headingList={{ 0: AFFILIATIONS_PAGE.BusinessTaxonomy, 2: AFFILIATIONS_PAGE.SubjectTaxonomy }}
        headingPos={[0, 2]}
        offset={[-515, -30]}
        trigger={
          <button
            type="button"
            className="case-details__topic-box__share position-absolute "
            data-testid="topics-picker"
          >
            <IconS name="Selector" size={26} />
          </button>
        }
        disabledGuids={topicList?.map((topic) => ({ guid: topic?.id, displayName: topic?.name }))}
        disabledGuidMsg={AFFILIATIONS_PAGE.disabledExpertiseMsg}
        cbOnSelection={({ term }) => {
          handleAddTopic(term);
        }}
        disabledLevelStartIndex={1}
      />
    ),
    [isEditable, newlyAddedTopics, topicList]
  );

  const SkillsTaxonomy = useCallback(
    () => (
      <TaxonomySelector
        placeholder={AFFILIATIONS_PAGE.SearchSkill}
        endpoint={apiUrl.smartLogicApi}
        tbdb={AFFILIATIONS_PAGE.SkillsAndTools}
        headingList={{ 0: AFFILIATIONS_PAGE.SkillsTaxonomy, 1: AFFILIATIONS_PAGE.ToolsTaxonomy }}
        headingPos={[0, 1]}
        offset={[-515, -30]}
        trigger={
          <button
            type="button"
            className="case-details__topic-box__share position-absolute "
            data-testid="skills-picker"
          >
            <IconS name="Selector" size={26} />
          </button>
        }
        disabledGuids={skillList?.map((skill) => ({ guid: skill?.id, displayName: skill?.name }))}
        disabledGuidMsg={AFFILIATIONS_PAGE.disabledSkillMsg}
        cbOnSelection={({ term }) => {
          handleAddSkill(term);
        }}
        disabledLevelStartIndex={1}
      />
    ),
    [isEditable, newlyAddedSkills, skillList]
  );

  return (
    <>
      <Modal
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        centered={false}
        closeOnDimmerClick={false}
        className={` ${openWarnPopup ? "d-none" : ""}  custom-modal-editProfile case-details`}
      >
        <Modal.Content className="pl-0 pr-0 pt-0 position-relative">
          {saveLoading ? (
            <Segment className="case-details__loadingSegment">
              <Dimmer active inverted>
                <Loader size="large">Saving</Loader>
              </Dimmer>
            </Segment>
          ) : null}
          <Grid>
            <Grid.Row className="pl-3 pr-3 case-details__titleRow">
              <Grid.Column computer={10} tablet={12}>
                <div>
                  <p className="case-type">{case_type}</p>
                  <CaseTitle
                    shortDescription={case_short_desc}
                    sortBy={sortBy}
                    docRank={rank}
                    projectId={crawl_item_id}
                    getFilterterm={() => {}}
                    className={case_short_desc ? "case-details__title" : "case-details__noDesc"}
                  />
                  {case_number && case_number !== "restricted" && (
                    <CopyText
                      text={case_number}
                      cls="case-details__caseNumber"
                      prefix="#"
                      popUpText={CASES.CopyNumber}
                    />
                  )}
                </div>
              </Grid.Column>
              <Grid.Column computer={6} tablet={12}>
                <div className="d-flex justify-content-end">
                  <div className="case-details__hours p-1">
                    <p className="mr-h">{caseHrs}</p> {CASES.CaseDetailsHours}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="pl-3 pr-3 case-details__metaDataRow">
              <Grid.Column computer={16} tablet={16}>
                <div className="case-details__metadata">
                  {/* Showing/hiding fields based on values existance */}
                  {date_opened_actual && moment(date_opened_actual).format("MMMM YYYY")}{" "}
                  {client_name && client_name !== "restricted" && ` - ${client_name}`}{" "}
                  {office_name && (
                    <span className="case-details__location">
                      <img src="/icons/Location.svg" alt="" width={14} />
                      {office_name}
                    </span>
                  )}
                  {vignettesCount > 0 && <span>|</span>} {vignettesCount > 0 && <b>{vignettesCount}</b>}{" "}
                  {vignettesCount > 0 && `${CASES.Vignette}${vignettesCount > 1 ? "s" : ""}`}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="pl-3 pr-3 pt-0">
              <Grid.Column computer={16} tablet={16}>
                {(tags?.length && (
                  <div>
                    {tags.map((tag) => (
                      <Label
                        data-testid="tags"
                        className="case__tag-readOnly case-details__tags cases-tags cursor-pointer case__tag-readOnly__overflow overflow-hidden"
                        key={tag}
                      >
                        {tag}
                      </Label>
                    ))}
                  </div>
                )) || <p className="case__tag-readOnly__no-tags">{CASES.NoCaseTopics}</p>}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <hr />
          <Grid>
            <Grid.Row className="pl-3 pr-3 mt-1">
              {/* expertise section */}
              <Grid.Column computer={8} tablet={12}>
                <h4>{CASES.ExpertiseWorked}</h4>
                {!isEditable ? (
                  <div className="case-details__pipedList">
                    <PipedList items={topicList} />
                  </div>
                ) : (
                  <div
                    className={`case-details__topic-box position-relative people-picker${!id ? " disabled-input" : ""}`}
                  >
                    <div
                      className="case-details__topic-box__scroll-container case-details__topic-box__scroll-container--topics"
                      ref={targetRefTopic}
                    >
                      {topicList?.map((i) => (
                        <span className=" chip small mb-h mr-h " data-testid="chip-topics" key={i.id}>
                          <span className="mr-h">{i.name}</span>
                          <Icon name="delete" data-testid="delete" onClick={() => handleDeleteTopic(i?.id)} />
                        </span>
                      ))}
                    </div>
                    <TopicTaxonomy />
                    <div className="case-details__topic-box__scroll position-absolute">
                      <button
                        type="button"
                        onClick={(e) => scroll(e, "UP")}
                        data-testid="btn-up"
                        disabled={!topicScrollable}
                      >
                        <Icon name="angle up" />
                      </button>
                      <button
                        type="button"
                        onClick={(e) => scroll(e, "DOWN")}
                        data-testid="btn-down"
                        disabled={!topicScrollable}
                      >
                        <Icon name="angle down" />
                      </button>
                    </div>
                  </div>
                )}
              </Grid.Column>
              {/* skills section */}
              <Grid.Column computer={8} tablet={12}>
                <h4>{CASES.SkillsWorked}</h4>
                {!isEditable ? (
                  <div className="case-details__pipedList">
                    <PipedList items={skillList} />
                  </div>
                ) : (
                  <div
                    className={`case-details__topic-box position-relative people-picker mb-2 ${
                      !id ? "disabled-input" : ""
                    }`}
                  >
                    <div
                      className="case-details__topic-box__scroll-container case-details__topic-box__scroll-container--skills"
                      ref={targetRefSkill}
                    >
                      {skillList?.map((i) => (
                        <span className=" chip small mb-h mr-h" data-testid="chip-skills" key={i.id}>
                          <span className="mr-h">{i.name}</span>
                          <Icon name="delete" data-testid="delete" onClick={() => handleDeleteSkill(i?.id)} />
                        </span>
                      ))}
                    </div>

                    <SkillsTaxonomy />

                    <div className="case-details__topic-box__scroll  position-absolute">
                      <button
                        type="button"
                        onClick={(e) => scroll(e, "UP")}
                        disabled={!skillScrollable}
                        data-testid="btn-up"
                      >
                        <Icon name="angle up" />
                      </button>
                      <button
                        type="button"
                        onClick={(e) => scroll(e, "DOWN")}
                        disabled={!skillScrollable}
                        data-testid="btn-down"
                      >
                        <Icon name="angle down" />
                      </button>
                    </div>
                  </div>
                )}
              </Grid.Column>
              <Grid.Column computer={16} className="mt-1">
                <h4>{CASES.CASEDESCRIPTION}</h4>
                <Description description={case_full_desc} noDescMsg={CASES.NoCaseDescription} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {/* case vignettes section */}
          {casevignettes && casevignettes?.length > 0 && (
            <>
              <Grid>
                <Grid.Row className="pl-3 pr-3 mt-1">
                  <Grid.Column>
                    <h4 className="mb-1">{CASES.CASEVIGNETTES}</h4>
                    <Vignettes
                      vignettes={casevignettes}
                      caseNumber={case_number}
                      clientDescription={client_desc}
                      caseOpenDate={date_opened_actual}
                      caseType={case_type}
                      shortDescription={case_short_desc}
                      projectId={crawl_item_id}
                      docRank={rank}
                      textDescription={case_full_desc}
                      description={case_full_desc}
                      caseVignettesStatus={casevignettesstatus}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          )}
          {/* case team member table */}
          {all_case_team && all_case_team?.length > 0 && (
            <>
              <Grid>
                <Grid.Row className="pl-3 pr-3 mt-1">
                  <Grid.Column>
                    <h4 className="mb-1">{CASES.CASETEAM}</h4>
                    <Team
                      team={all_case_team}
                      origin="case"
                      sortBy={sortBy}
                      className="caseTeam-placeholder"
                      headerWidth={[5, 4, 4]}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          )}
        </Modal.Content>
        <Modal.Actions className="footer">
          {!isEditable ? (
            <Button basic secondary onClick={onClose}>
              {COMMON.Close}
            </Button>
          ) : (
            <>
              <Button basic secondary onClick={checkDirty}>
                {COMMON.Cancel}
              </Button>
              <Button primary type="submit" disabled={!isdirty || saveLoading} onClick={handleSave}>
                {COMMON.Save}
              </Button>
            </>
          )}
        </Modal.Actions>
      </Modal>
      <ConfirmationModal
        isOpen={openWarnPopup}
        modalMessage={EDIT_CASES.Warnmesg}
        handleCancelClick={() => setOpenWarnPopup(false)}
        handleContinueClick={onClose}
      />
    </>
  );
};

ViewCaseDetails.defaultProps = {
  caseObj: {
    crawl_item_id: "",
    case_number: "",
    case_type: "",
    date_opened_actual: "",
    caseCloseDate: "",
    shortDescription: "",
    office_name: "",
    casevignettes: [],
    client_name: "",
    industrypas: [],
    functionalpas: [],
    caseHours: [],
    case_full_desc: "",
    client_desc: "",
    all_case_team: [],
    topics: [],
    textDescription: "",
    casevignettesstatus: "",
    totalCaseHours: 0
  },
  sortBy: "",
  rank: 0,
  open: false,
  onOpen: () => {},
  onClose: () => {},
  caseExpertise: [],
  caseSkills: [],
  isEditable: false,
  pageNumber: 0,
  localDispatch: () => {}
};

ViewCaseDetails.propTypes = {
  caseObj: PropTypes.shape({
    crawl_item_id: PropTypes.string,
    case_number: PropTypes.string,
    case_type: PropTypes.string,
    date_opened_actual: PropTypes.string,
    caseCloseDate: PropTypes.string,
    case_short_desc: PropTypes.string,
    office_name: PropTypes.string,
    casevignettes: PropTypes.arrayOf(PropTypes.object),
    client_name: PropTypes.string,
    industrypas: PropTypes.arrayOf(PropTypes.object),
    functionalpas: PropTypes.arrayOf(PropTypes.object),
    caseHours: PropTypes.arrayOf(PropTypes.object),
    case_full_desc: PropTypes.string,
    client_desc: PropTypes.string,
    all_case_team: PropTypes.arrayOf(PropTypes.object),
    topics: PropTypes.arrayOf(PropTypes.shape()),
    textDescription: PropTypes.string,
    casevignettesstatus: PropTypes.string,
    totalCaseHours: PropTypes.number
  }),
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  sortBy: PropTypes.string,
  rank: PropTypes.number,
  caseExpertise: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
  caseSkills: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
  isEditable: PropTypes.bool,
  pageNumber: PropTypes.number,
  localDispatch: PropTypes.func
};

export default ViewCaseDetails;
