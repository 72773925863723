import { call, put } from "redux-saga/effects";
import { post } from "redux/api";
import { setRecentSSMaterials, setError } from "redux/actions/ssRecentMaterials";

function* handleFetchSSRecentMaterials({ payload }) {
  const { res, error } = yield call(post, payload);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setRecentSSMaterials(res.data));
  }
}

export default handleFetchSSRecentMaterials;
