const LABEL = {
  COMMON: {
    SOURCE_NAME: "source",
    Expand: "expand",
    ViewAll: "View All",
    Name: "Name",
    Title: "Title",
    Office: "Office",
    CasePosition: "Case Position",
    Assistant: "Assistant",
    CopyProfileURL: "Copy Profile URL",
    ContactAssistant: "Contact assistant",
    GoToProfile: "Go to Profile",
    SendAnEmail: "Send an email",
    Email: "Email",
    Slack: "Slack",
    Copied: "Copied",
    ViewProfile: "View Profile",
    Cancel: "Cancel",
    Yes: "Yes",
    No: "No",
    Save: "Save",
    Continue: "Continue",
    Proceed: "Proceed",
    SuccessMsgHeader: "Saved Successfully",
    SuccessMsgDesc: "Your updates have been successfully saved.",
    ExpertiseSaveSuccessMsg:
      "Your expertise has been added. Please note that it may not appear at the top of the list, as the entries are sorted by recent cases.",
    SuccessMsgIcon: "check circle",
    DeleteMsgHeader: "Deleted Successfully",
    DeleteMsgDesc: "Record have been successfully deleted.",
    DeleteMsgIcon: "check circle",
    ErrorMsgHeader: "Network Error",
    ErrorMsgDesc: "Some error occurred. Please try again.",
    ErrorMsgIcon: "times circle",
    FileNotFoundHeader: "Not Found (404)",
    FileNotFoundDesc: "This file is not available.",
    Close: "Close",
    Warnmesg: "Are you sure want to Cancel? All your changes will be lost.",
    Saving: "Saving",
    CancelWarn: "Your unsaved changes will be lost. Are you sure you want to continue?",
    Apply: "Apply",
    Clear: "Clear",
    Deleting: "Deleting",
    FILTER: "filter",
    MoreFilters: "More Filters",
    SORT: "sort",
    ValidationWarning: "Please fill the mandatory fields to proceed with saving the updates.",
    BACK: "Back",
    DeleteRecord: "Are you sure you want to delete this record",
    UnsavedConfirmation: "All your unsaved data will be lost, are you sure you want to continue?",
    FilterApplied: "Filter Applied",
    FiltersApplied: "Filters Applied",
    ClearAll: "Clear All",
    Export: "Export",
    ExportInProgress: "Exporting",
    StatusProcessing: "Processing",
    StatusSuccess: "Success",
    StatusFailure: "Failure",
    Seconds: "Seconds",
    DefaultCopyText: "Copy Text",
    CopySuccess: "Copied Successfully !",
    Decline: "Decline",
    Allow: "Allow",
    SaveAndClose: "Save & Close",
    Present: "Present",
    NA: "NA",
    Hidden: "Hidden",
    Expired: "Expired",
    Settings: "Settings",
    EditOnWorkDay: "Edit on Workday",
    Edit: "Edit",
    OktaToken: "okta-token-storage_accessToken",
    HowCanIbeMoreFindablekMe: "How can I be more findable?"
  },
  BANNER: {
    Salutation: "Salutation",
    LocalTime: "Local Time",
    StartDate: "Start Date",
    BirthDate: "Birth Date",
    OfficeLocation: "Office Location",
    LOA: "Leave of Absence",
    LOAStatus: ["On Leave"],
    EditWorkDayFields: "edit workday fields",
    GoToWorkDay: "Go to workday",
    Settings: "Settings",
    HiddenBirthDate: "Not available",
    PHONELINKS: {
      Office: "Office",
      Office1: "Office 1",
      Office2: "Office 2",
      Mobile: "Mobile",
      Work: "Work"
    },
    ADMINLINKS: {
      ViewAdditionalSystemFields: "View Additional System Fields",
      ViewProfileUpdateHistory: "View Profile Update History",
      ImpersonateUser: "Impersonate User",
      ChangeUserAccessRole: "Change User's Access Role",
      ReinstateUserAccessIPGuardian: "Reinstate User Access ( IP Guardian )"
    },
    TOOLTIPS: {
      ProfileImage: "View Profile Image",
      Phone: "View Phone Numbers",
      ContactNumberCopy: "Copy Number",
      ContactNumberCopyDone: "Copied Number",
      Email: "Send Email",
      EmailCopy: "Copy Email ID",
      EmailCopyDone: "Copied Email ID",
      Slack: "Start Slack conversation",
      Settings: "Admin options",
      PreferredContact: "Preferred Contact Method",
      FaceCardGenerator: "Face Card Generator",
      FaceCardGeneratorSubTitle: "to download multiple images"
    },
    Anonymized: "Profile of this has been anonymized, thus some of the information is not displayed any longer.",
    Update: "Update"
  },
  EXPERIENCE: {
    Experience: "Experience",
    NoExpAdded: "No Experience Added",
    AddExperience: "Add Experience",
    ExperienceTooltip: "",
    TABLE_HEADER: {
      CompanyOrganization: "Company / Organization",
      Role: "Role",
      StartEndDate: "Start / End Date",
      Division: "Division",
      Location: "Location",
      Comment: "Comment"
    },
    WorkdayMessageExperience:
      "Any changes to Pre-BCG Work Experience can only be updated in Workday. Click on pencil to access the Workday Portal.",
    LinkedInMessageExperience:
      "Any changes to Pre-BCG Work Experience can only be updated in LinkedIn. Click on pencil to access the LinkedIn Portal."
  },
  PROFILE_SUMMARY: {
    ProfileSummary: "Profile Summary",
    PersonalInterests: "Personal Interests",
    Proposal: "Proposal Bio",
    Resume: "Resume",
    NoProfileSummaryAdded: "No Profile Summary Added",
    ProposalBio: "Proposal Bio/Resume",
    NoProposalBioAdded: "No Proposal Bio/Resume Added",
    ProposalBioResumeInfo:
      "Downloading these documents will share your name, title, cohort and email details with the profile owner to enhance collaboration."
  },
  LANGUAGE_PROFICIENCIES: {
    TITLE: "Languages",
    TABLE_HEADERS: {
      LANGUAGE: "Language",
      SKILL_PRESENT: "Present",
      SKILL_READ: "Read",
      SKILL_SPEAK: "Speak",
      SKILL_WRITE: "Write"
    },
    PROFICIENCY_LEVELS: {
      BEGINNER: "Beginner",
      INTERMEDIATE: "Intermediate",
      ADVANCED: "Advanced",
      FLUENT: "Fluent",
      NATIVE: "Native"
    }
  },
  PROFILE_OVERVIEW: {
    ProfileOverview: "Profile Overview",
    UpdateOverview: "Update Overview",
    PerCompletion: "% Profile completion",
    FieldLeftIncomplete: "Field left incomplete",
    FieldsLeftIncomplete: "Fields left incomplete",
    CasesWorkingOn: "Cases worked upon",
    TotalCaseHours: "Total case hours",
    CVBioDownlods: "CV/Bio Downloads",
    MaterialDownloads: "Material Downloads",
    LastUpdate: "Last Update",
    ProfileViews: "Profile Views",
    UpdateNow: "Update Now",
    SkipForNow: "Skip for now",
    ProfileConfirmation: "Profile Confirmation",
    DateFormat: "MMM YYYY",
    LastUpdateDate: "lastUpdateDate",
    RedCrossIcon: "red-cross",
    RedCrossSVGIcon: "red-cross.svg",
    GreenTickIcon: "green-tick",
    GreenTickSVGIcon: "green-tick.svg",
    YelloExclamationIcon: "yellow-exclamation-filled",
    CvBioFields: ["resume", "Proposal Bio"],
    ProfileSummaryFields: ["summary"],
    ProfileCompletionHoverTitle: "Here's how the completion percentage is updated :",
    PrivateToYou: "Private to you",
    NonCTUserInfoData: [
      "About tile - 20%",
      "Summary - 40%",
      "CV/Bio - 30%",
      "Time (Profile updated in last 6-12 months) - 10%"
    ],
    CTUserInfoData: ["Summary - 50%", "CV/Bio - 40%", "Time (Profile updated in last 6-12 months) - 10%"],
    AboutTileData: [
      "Showcases featured expertise",
      "Boosts your search relevancy",
      "Could use to track topic bill-ability"
    ],
    AboutTileKTFields: ["KT practiceAreaGuid", "KT primaryTopicGuid", "KT subTopicGuid"],
    AboutTileECTFields: ["ECT Sponsor HrId", "ECT Sponsor PracticeAreaGuid", "ECT Primary TopicGuid"],
    AboutTileSAFields: ["SA practiceArea1Guid", "SA sponsor1HrId"],
    ProfileSummaryData: [
      "Informs search with keywords like granular business topics, and relevant experiences or degree details."
    ],
    CvBioData: ["Used for proposals"],
    SkipForNowPopupMessage: (profileViewsCount, resumeDownloadsCount) =>
      `We average ${profileViewsCount} profile views and ${resumeDownloadsCount} proposal bio downloads a month, are you sure you want to skip this step?`
  },
  EDUCATION: {
    Education: "Education",
    NoEducation: "No Education Added",
    AddEducation: "Add Education",
    TABLE_HEADER: {
      School: "School",
      Degree: "Degree",
      Major: "Major",
      Duration: "Duration",
      StartEndDate: "Start / End Date"
    },
    WorkdayMessageEducation:
      "Any changes to Education can only be updated in Workday. Click on pencil to access the Workday Portal.",
    LinkedInMessageEducation:
      "Any changes to Education can only be updated in LinkedIn. Click on pencil to access the LinkedIn Portal."
  },
  ACHIEVEMENTS: {
    Credentials: "Credentials",
    Awards: "Awards",
    AddNewAward: "Add New Award",
    Select: "Select",
    Award: "award",
    ViewMoreDetails: "View More Details",
    NoExternalCredAdded: "No External Credentials Added",
    NoBcgCredAdded: "No BCG Credentials Added",
    AddBcgCredential: "Add BCG Credential",
    AddExternalCred: "Add External Credential",
    Certifications: "Certifications",
    ExternalCred: "External Credentials",
    ExternalCredSource: "external",
    BcgCredSource: "internal",
    BCGCred: "BCG Credentials",
    FeaturedCredentialsLabel: "Featured Credentials",
    NoAwardAdded: "No Awards Added",
    AddAward: "Add Award",
    BcgCredsDragDropRestrictedMessage: "Drag and Drop restricted for expired / hidden credentials",
    ExternalCredsDropRestrictedMessage: "Drag and Drop restricted for expired credentials",
    BCG: "BCG",
    Bcg: "bcg",
    External: "External",
    emptyValue: "-",
    KeyToPurpose: "Key To Purpose",
    BackToCredentials: "Back to Credentials",
    AddNewCred: "Add New Credential",
    DuplicateCertificateMessage: "Please make sure, the certificate you are adding does not already exist",
    AddNewExternalCred: "Add New External Credential",
    HiddenCred: "Hidden Credential",
    ExpiredCredential: "Expired Credential",
    NoCertificateAdded: "No Certificates Added",
    AddCertificate: "Add Certificate",
    DateFormat: "dd/MMM/yyyy",
    invalidTextWarningMessage: "no special characters allowed",
    CourseIdWarning: "Course ID already exists.",
    DateReceived: "dateReceived",
    SortOrder: "sortOrder",
    ICONS: {
      KeyToPurposeIcon: "keyToPurpose",
      ExternalAwardIcon: "externalAward",
      InternalAwardIcon: "internalAward",
      ExternalCredential: "externalCredential",
      InternalCred: "internalCredential"
    },
    ALL_CERTIFICATIONS: {
      TABLE_HEADER: {
        CertificationName: "Certification Name",
        DateReceived: "Date Received",
        DateAwarded: "Date Awarded",
        CertificateAuthority: "Certificate Authority",
        CourseId: "Course ID",
        Level: "Level",
        Validity: "Validity",
        Skills: "Skills",
        Description: "Description"
      }
    },
    GridHeader: {
      CredentialDetails: "Credential Details",
      Skills: "Skills",
      Actions: "Actions"
    },
    NoCredentialsMsg: "You haven't added any credential yet."
  },
  SYSTEM_PROFILE_VALUES: {
    SystemProfileValue: "System Profile Values",
    FirstNameAlias: "First Name Alias",
    WindowsLogon: "Windows Logon",
    TerminationDate: "Termination Date",
    LastNameAlias: "Last Name Alias",
    Status: "Status",
    IsActive: "Is Active",
    SearchAlias: "Search Alias",
    NavAccessRole: "Nav Access Role",
    LOAFlagLimitAccessCode: "LOA Flag/Limit Access Code",
    TBID: "T&B ID",
    StatusComment: "Status Comment",
    businessTitle: "Global Title",
    HRID: "HR ID",
    CareerTrack: "Career Track",
    TimeZoneName: "Time Zone Name",
    HRPhoneNumber: "HR Phone Number",
    BackgroundUpdateDate: "Background Update Date",
    TimeZoneOffset: "Time Zone Offset",
    ADLogin: "AD Login",
    TermsAgreeDate: "Terms Agree Date",
    ProfileLastUpdateDate: "Profile Last Update Date",
    ADDomain: "AD Domain",
    HRLastUpdateDate: "HR Last Update Date",
    Close: "Close",
    True: "True",
    False: "False"
  },
  CASES: {
    Cases: "Cases",
    Case: "Case",
    UsersHours: "Total hours of contribution",
    CaseDetailsHours: "User's Total Case hours",
    Expand: "Expand",
    CopyNumber: "Copy Case Number",
    Copied: "Case Number Copied",
    ViewCases: "View All Cases",
    AllCases: "All Cases",
    NoCasesAdded: "No Cases Added",
    Vignette: "Vignette",
    ViewAll: "View All",
    CasesUrl: "https://pages.navigator.bcg.com/cp/",
    OpenCasePage: "Open Case page",
    CASEDESCRIPTION: "CASE DESCRIPTION",
    CASEVIGNETTES: "CASE VIGNETTES",
    CASETEAM: "CASE TEAM",
    Collapse: "Collapse",
    SearchAllCases: "Search All Cases",
    TotalCaseHours: "Total Case Hours",
    TotalCaseHour: "Total Case Hour",
    RecentCases: "Recent Cases",
    ExpertiseWorked: "Expertise worked upon",
    SkillsWorked: "Skills worked upon",
    Responsibility: "Case Responsibility",
    ResponsibilityPlaceholder: "Add Case Responsibility",
    NoCasesFound: "No Case Found",
    // EditWarn: "Topics/Skills Worked On will be available to edit within the next 24-48 hours.",
    EditWarn: "Coming soon! You'll be able to add/edit your topics and skills.",
    TopicsSkillsWarn: "Topics/Skills worked upon will be available soon.",
    ExportType: "Case",
    NoCaseDescription: "No Case Description Available",
    NoCaseDescriptionDash: "-",
    NoCaseTopics: "No Case Topics Available",
    ViewDetails: "View More Details",
    ViewEditDetails: "View and Edit Details"
  },
  MATERIALS: {
    ViewDetails: "View More Details",
    InternalMaterialFilterWarning: "All pre-selected filters will be removed, do you want to Continue ?",
    NoMaterialTopic: "No Material Topics Available",
    NoMaterialDescription: "No Material Description Available",
    BCGInternalmaterial: "BCG Internal Material",
    Internal: "Internal",
    ShareViaEmail: "Share via Email",
    CopyLinkToKnowledgePage: "Copy Link to Knowledge Page",
    CopyLinkToAttachment: "Copy Link to Attachment",
    SharedFromNavigator: "Shared from Navigator",
    DirectDownload: "Direct Download",
    Description: "Description",
    FullDetails: "View full details on Navigator",
    NoMaterials: "No Materials Added",
    ContributeKnowledge: "Contribute Knowledge",
    Expand: "Expand",
    Collapse: "Collapse",
    OpenKnowledgePage: "Open Knowledge Page",
    Share: "Share",
    Download: "Download",
    Preview: "Preview",
    Pin: "Pin",
    URL: "URL",
    ViewAll: "View All Materials",
    Copied: "Copied",
    AssociatedBundles: "Associated Bundles",
    Authors: "Authors",
    ViewBundlePage: "View Bundle Page",
    CopyBundleURL: "Copy Bundle URL",
    TotalDownloads: "Total Downloads",
    TotalDownload: "Total Download",
    Materials: "Materials",
    Material: "Material",
    AllMaterials: "All Materials",
    SearchAllMaterials: "Search All Materials",
    RecentMaterials: "Recent Materials",
    ViewMaterials: "View All Materials",
    ExportType: "Material",
    InternalMaterials: "Internal Materials",
    InternalMaterial: "Internal Material"
  },
  VIGNETTES: {
    ShareViaEmail: "Share via Email",
    CopyLinkToCasePage: "Copy Link to Case page",
    CopyLinkToVignettes: "Copy Link to Vignette",
    Copied: "Copied",
    Share: "Share",
    DocumentName: "Document Name",
    Updated: "Updated",
    Downloads: "Downloads",
    Actions: "Actions",
    Email: "Email",
    Download: "Download",
    EXCEEDED_MAX: "Unable to email more than 8 items at once.",
    NoPermission: "You don't have permission to see Vignettes"
  },
  EXPERTISE: {
    Expertise: "Expertise",
    NoExpertise: "No Expertise Added",
    AddExpertise: "Add Expertise",
    EditExpertise: "EDIT EXPERTISE & SKILLS",
    How: "How expertise is calculated?",
    Para1:
      "This summary is a reflection of your top 3 topic rankings relative to other experts in the firm. This takes into account all information on your profile with a particular focus on the following areas: Topic Role / Proficiency, Case Hours, Materials Authored, and Position. You can influence your Expertise Summary by ensuring that all elements of your profile are updated to accurately reflect your expertise. Please note that your Expertise Summary will not reflect any profile changes until the following day.",
    Para2: "If you have further questions, please reach out to ",
    EmailText: "Knowledge Systems Support",
    Email: "KnowledgeSystemsSupport@bcg.com",
    ViewAll: "View All Expertise / Affiliations",
    TopicExpertiseSummary: "Topic Expertise Summary",
    UPDATE: "ADD EXPERTISE & SKILLS",
    AddNewSkill: "Add New Skill",
    AddNewExpertise: "Add New Expertise",
    ADD_EXPERTISE: {
      AddYourExpertise: "Add your Expertise in just two steps",
      SearchAndAdd: " Search and add any number of expertise",
      AddedPASkillsExpertise: "Added PA/Subjects/Skills Expertise",
      AddProficiency: " ADD PROFICIENCY",
      Saving: "Saving...",
      DragAndDrop: "Drag and Drop expertise / skills to level of proficiencies",
      DragAndDropHere: "Drag and drop here",
      BackToSearch: "BACK TO SEARCH",
      Submit: "SUBMIT",
      DisableMsg:
        "The submit button will remain inactive until all added expertise have been assigned a proficiency level."
    }
  },
  SORT: {
    Relevancy: "Relevancy",
    MostRecent: "Most Recent",
    Downloads: "Downloads",
    CaseHours: "Case Hours"
  },
  FILTER: {
    Industry: "Industry",
    Function: "Function",
    Regions: "Regions",
    Subject: "Subject Area",
    Proficiency: "Proficiency",
    Roles: "Roles",
    Affiliation: "Affiliation",
    CaseType: "Case Type",
    MaterialType: "Material Type",
    DateRevised: "Date Revised",
    DateRange: "Date Range"
  },
  ABOUT: {
    About: "About",
    CCO: "CCO",
    disabledOptionText: "This Practice Area is already selected.",
    PracticeAreaExpertise: "Practice Area Expertise",
    SenAdvSponser1: "Sen. Adv. Sponsor 1",
    SenAdvSponser2: "Sen. Adv. Sponsor 2",
    PrimaryTopic: "Primary Topic",
    SubTopic: "Sub Topic",
    Track: "Track",
    Sponsor: "Sponsor",
    SponsorPA: "SponsorPA",
    EditAbout: "About - Edit",
    ContactHR: "Contact local HR to update your position information.",
    Warnmesg: "Are you sure want to Cancel? All your changes will be lost.",
    PracticeArea1: "Practice Area (1)",
    PracticeArea2: "Practice Area (2)",
    SeniorAdvSponser1: "Sen.Adv.Sponsor (1)",
    SeniorAdvSponser2: "Sen.Adv.Sponsor (2)",
    Position: "Position",
    SeniorAdv: "Senior Advisor",
    AboutMeTaxonomy: "AboutMe",
    DisableButtonToolTip: "Please complete all mandatory fields.",
    EDIT: {
      SRADVISOR: {
        TITLE: "Senior Advisor",
        PA1: "Practice Area (1)",
        PA2: "Practice Area (2)",
        Sponsor1: "Sen.Adv.Sponsor (1)",
        Sponsor2: "Sen.Adv.Sponsor (2)",
        PAPlaceholderText: "Select Practice Area"
      },
      ECT: {
        Title: "Expert Career Track",
        Sponsor: "Sponsor",
        SponsorPA: "Sponsor PA",
        SponsorPAPlaceholderText: "Select Sponsor Practice Area",
        SelectPA: "Select Practice Area",
        PrimaryTopic: "Primary Topic"
      },
      KT: {
        Title: "Knowledge Expert",
        ContactSC1: "Contact",
        ContactSC2: "Service Center",
        ContactSC3: "to update any details given below .",
        ContactSCEmail: "mailto:KnowledgeSystemsSupport@bcg.com",
        PracticeArea: "Practice Area",
        PrimaryTopic: "Primary Topic",
        SubTopic: "Sub Topic"
      }
    }
  },
  AFFILIATIONS_PAGE: {
    Title: "Expertise / Affiliations",
    Expertise: "Expertise",
    Skills: "Skills",
    PracticeArea: "Practice Area",
    SkillArea: "Skill Area",
    Topic: "Topic",
    Skill: "Skill",
    Proficiency: "Proficiency",
    Roles: "Roles",
    Affiliation: "PA Affiliation",
    Description: "Description",
    NoTopics: "No Topics Added",
    NoSkills: "No Skills Added",
    BCG: "BCG",
    ThoughtLeader: "Thought Leader",
    Advanced: "Advanced",
    Experienced: "Experienced",
    BASIC: "Basic",
    NOTYETSUBMITTED: "Not yet submitted",
    PENDINGAPPROVAL: "Pending Approval",
    SearchSkill: "Search Skills and Tools",
    SearchExpertise: "Search Business and Subjects",
    SkillsAndTools: "SkillsAndTools",
    BusinessAndSubject: "TypeAheadFPA-IPA-Subjects",
    SkillsTaxonomy: "Skills Taxonomy",
    ToolsTaxonomy: "Tools & Solutions Taxonomy",
    BusinessTaxonomy: "Business Taxonomy",
    SubjectTaxonomy: "Subject Taxonomy",
    SkillTaxonomyPlaceholder: "Select Skill",
    ExpertiseTaxonomyPlaceholder: "Select Expertise",
    ProficiencyPlaceholder: "Select Proficiency",
    disabledSkillMsg: "This Topic is already added to your skills.",
    disabledExpertiseMsg: "This Topic is already added to your expertise.",
    AddNewExpertise: "Add New Expertise",
    deleteSkills: "Are you sure you want to delete this Skill?",
    deleteExpertise: "Are you sure you want to delete this Expertise?"
  },
  EDIT_PROFILE: {
    EditProfile: "Edit Your Personal Details",
    ContactHR: "Contact local HR to update your personal image and personal details.",
    UpdateFromWorkdayStart: "Your personal image and details can be updated in the",
    UpdateFromWorkdayEnd: "Personal Information Application.",
    TIPS: "TIPS",
    TipsPI: "Adding your personal interest will help you to find more connection in BCG network.",
    TipsLA: "This will help people find you with specific case related needs.",
    PersonalInterests: "Personal Interests",
    ProfileSummary: "Profile Summary",
    Languages: "Languages",
    TipsSM: "Here are some suggestions to help you build a summary about you.",
    TipsSMone: " Highlight industries that you've worked in previously",
    TipsSMtwo: "Add types to deliverables that you are an expert in",
    TipsSMthree: " Provide background details on your career goal",
    Descriptions: "Descriptions",
    TipsPBOne: "Keep your Proposal Bio/Resume up-to-date. It is valuable for proposals and new case team engagements.",
    TipsPBTwo: "You can upload any number of Proposal Bio but only one resume.",
    TipsPBThree: "Valid file formats - .pdf, .doc, .docx, .ppt, .pptx, .txt, .rtf, .jpg, .jpeg, .tiff, .gif and .png.",
    TipsPBFour: "File size should not exceed 10 MB.",
    ProposalBio: "Proposal Bio/Resume",
    NamePB: "Name",
    TypePB: "Type",
    UploadedDatePB: "Uploaded Date",
    LanguagePB: "Language",
    Proposal: "Proposal",
    Resume: "Resume",
    ProposalIpt: "Proposal Bio",
    ResumeIpt: "Resume",
    NoProposalBioAdded: "No Proposal Bio/Resume Added",
    DownloadPB: "Download",
    DeletePB: "Delete",
    DeletePBMsg: "Are you sure you want to delete? This operation cannot be undone.",
    LanguagePBTxt: "Choose...",
    LanguagesPB: ["English", "French", "German", "Spanish", "Chinese", "Dutch", "Finnish"],
    AllowedExtPB: [".pdf", ".doc", ".docx", ".ppt", ".pptx", ".txt", ".rtf", ".jpg", ".jpeg", ".tiff", ".gif", ".png"],
    RestrictedExtPBMsg: "Invalid file format. Please check Tips section for details.",
    FileSizeWarning: "File too big. Please check Tips section for details.",
    FieldValidationMsg: "Please fill the mandatory fields to proceed with saving the updates.",
    Name: "Name",
    Position: "Position",
    Office: "Office",
    Email: "Email",
    Assistant: "Assistant",
    AssistantEmail: "Assistant's Email",
    HideDOB: "Hide Birth Date on Banner",
    PhoneNumber: "Phone Number",
    Mobile: "Mobile",
    Work: "Work",
    PREFFERED_CONTACT_TRIGGER: {
      OFFICE: "Office",
      MOBILE: "Mobile",
      WORK: "Work"
    }
  },
  ALL_AWARDS: {
    TABLE_HEADER: {
      AwardType: "Award Type",
      AwardCategory: "Award Category",
      AwardName: "Award Name",
      DateReceived: "Date Received",
      HyperlinkHeader: "Hyperlink",
      Description: "Description"
    }
  },
  TOPICS_FINDER: {
    TopicsFinder: "Topic Finder",
    SkillsFinder: "Skill Finder",
    NotFound: "Couldn't find what you are looking for? Try refining your search keyword",
    ContactUs: "Contact Us",
    CantFind: "Can't find the right Topic?",
    CantFindSkill: "Can't find the right Skill?",
    FilterWarning: "Filters are not available in add/edit mode. Filters will reset on clicking Continue.",
    SkillEmial: "SkillsProficiencyHelp@bcg.com",
    KnowledgeSupportEmailTopic: "KnowledgeSystemsSupport@bcg.com?subject=New Topic request",
    KnowledgeSupportEmailSkill: "KnowledgeSystemsSupport@bcg.com?subject=New Skill request"
  },
  THOUGHTLEADER: {
    title: "Thought Leader Confirmation",
    desc: "Please provide justification for why you should be considered a Thought Leader for this topic. This information will be reviewed by PA Management for acceptance after you have saved your profile. The Thought Leader proficiency will not show on your profile until it has been approved."
  },
  PROFILE_UPDATE_HISTORY: {
    ProfileUpdateHistory: "Profile Update History",
    ItemCollectionTerm: "history",
    TABLE_HEADER: {
      ChangeDate: "Change Date",
      FieldChanged: "Field Changed",
      ValueBefore: "Value Before",
      ValueAfter: "Value After",
      ChangedBy: "Changed By",
      Comment: "Comment"
    }
  },
  ALUMNI: {
    AlumniText: "ALUMNI",
    Since: "Since"
  },
  COMPLETION_BAR: {
    ProfileCompletion: "Profile completion",
    ProfileSummary: "Profile summary",
    Language: "Language",
    ProposalBio: "Proposal Bio",
    PreBCGExp: "Pre BCG Experience",
    Education: "Education"
  },
  EDIT_CASES: {
    EditCases: "Edit Cases",
    EditCase: "Edit Case",
    Warnmesg: "Are you sure want to Cancel? All your changes will be lost.",
    Search: "Search Cases"
  },
  FAQs: {
    Faq: "FAQ",
    FaqCaseListEmail:
      "KnowledgeSystemsSupport@bcg.com?subject=Enquiry on missing cases / billing hours in Navigator&amp;body=Hello, %0D%0A%0D%0A%0D%0APlease let me know if you can add my project code(s) to Navigator. %0D%0A%0D%0A Here is/ are my missing code(s) with case responsibilities: %0D%0A"
  },
  MANAGE_EXPERTISE: {
    ManageExpertise: "Manage Expertise Summary",
    Tips: "Tips",
    TipsContent: "You can show max 3 topics on your 'Expertise' card.",
    ShowExp: "Show Expertise"
  },
  ERRORS: {
    NewHeaderOOPS: "Oops!",
    NewTitle400: "We are sorry, Bad Request",
    NewMessage400: "Something went wrong, please click BACK TO MY PROFILE below and try again",
    NewTitle401: "We are sorry, you do not have sufficient privileges",
    NewMessage401: "Please contact IT service center to request access.",
    NewTitle403: "We are sorry, Page Forbidden",
    NewMessage403:
      "The page was valid, but the server is refusing action. The user might not have the\nnecessary permissions or a resource, or may need an account of some sort.",
    NewTitle404: "We are sorry, Page Not Found",
    NewMessage404:
      "The page you are looking for might have been removed, had its name changed\nor is temporarily unavailable.",

    NewTitle500: "Server Error",
    NewMessage500: "Something went wrong. Please try again.",
    NewTitleIE: "We are sorry, Profiles doesn't support Internet Explorer 11.",
    NewMessageIE: "Please use Chrome or Microsoft Edge. Both can be installed from your computer's Software Center.",
    NewTitleSafari: "We are sorry, Profiles doesn't support Safari browser.",
    NewTitleGen: "Something went wrong",
    NewMessageGen: "Something went wrong. Please try again.",
    NewTitleLOA: "You do not have access to this URL",
    NewMessageLOA: "To request different access permissions",
    NewTitleCT: "This person was an outside consultant and no longer supports BCG",
    GoHome: "Go To My Profile",
    ContactServiceCenter: "Contact IT Service Center",
    RequestAccess: "Request Access",
    NotFound: "Not Found",
    ServerError: "Server Error",
    AuthError: "Authentication Error",
    PageForbidden: "We are sorry, Page Forbidden",
    NetworkError: "Network Error",
    PeopleErrMsg: "Failed to load data from People API",
    CaseErrMsg: "Failed to load data from Case Search API",
    MaterialErrMsg: "Failed to load data from Knowledge Search API",
    LinkErrMsg: "Failed to load data from Profile API",
    BadGateway: "Bad Gateway.Failed to load data."
  },
  EMPLOYEE_STATUS: {
    CT: "CT", // CW Terminated
    LOA: "LOA"
  },
  ACCESS_ROLES: {
    Header: "Change Access Role",
    AccessRoleLabel: "Access Role",
    Comments: "Comments",
    UserNotAllowed: "User is not allowed to perform this action. (Admin only)"
  },
  IMPERSONATE: {
    Impersonating: "You are now impersonating :",
    Stop: "stop"
  },
  MANAGE_PRONUNCIATION: {
    Title: "Manage Pronunciation",
    InitialTitle: "Record Pronunciation",
    PlayPronunciation: "Play Pronunciation",
    StartRecording: "Start Recording",
    StopRecording: "Stop Recording",
    Recorded: "Recorded",
    Delete: "Delete",
    PlayBack: "Play Back",
    HelpText: "Record your name's pronunciation to enable your colleagues to speak your name well.",
    ProfileUploads: "Profiles Uploads",
    Playing: "Playing..."
  },
  ORG_STRUCTURE: {
    Title: "Org Structure",
    Manager: "Manager",
    Organization: "Organization"
  },

  CASE_QUERY_PARAMS: {
    SEARCHED_TERM: "query",
    ENABLE_AUTO_CORRECT: "enableAutoCorrect",
    HRID: "hrid",
    RESULTS_FROM_PAGE: "resultsFromPage",
    RESULTS_PER_PAGE: "resultsPerPage",
    SORTING_ORDER: "sortingOrder",
    VIEW_STATE: "viewState",
    CASE_TYPE: "caseType",
    INDUSTRY_PA: "industryPA",
    FUNCTIONAL_PA: "functionalPA",
    DATE_RANGE: "dateRange",
    VIGNETTES: "vignettes",
    REGIONS: "bcgRegionOffice",
    ELASTIC_DATA_REPOSITORY: "elastic",
    ELASTIC_SEARCH_METHOD: "bm25"
  },

  MATERIAL_QUERY_PARAMS: {
    SEARCHED_TERM: "query",
    ENABLE_AUTO_CORRECT: "enableAutoCorrect",
    HRID: "hrid",
    RESULTS_FROM_PAGE: "resultsFromPage",
    RESULTS_PER_PAGE: "resultsPerPage",
    SORTING_ORDER: "sortingOrder",
    VIEW_STATE: "viewState",
    MATERIAL_TYPE: "materialType",
    INDUSTRY_PA: "industryPA",
    FUNCTIONAL_PA: "functionalPA",
    SUBJECT_PA: "subjectPA",
    DATE_REVISED: "dateRevised",
    RECOMMENDED_BY: "recommendedBy",
    BCG_INTERNALS_ONLY: "bcgInternalsOnly"
  },
  LINKEDIN: {
    LINKED_IN: "LinkedIn",
    CANCEL_CONFIRMATION: "Your LinkedIn profile is not integrated with your KN Profile.",
    CANCEL_WARNING_MESSAGE:
      "You can still integrate your KN profiles with LinkedIn by clicking on LinkedIn settings button on the page",
    REASON_FOR_CANCELLATION: "Reason for declining LinkedIn Integration ?",
    SKIP_BUTTON_TEXT: "Skip",
    CONFIRM_BUTTON_TEXT: "Confirm",
    CONGRATULATIONS: "Congratulations!",
    SUCCESSFUL_INTEGRATION_MESSAGE:
      "Your LinkedIn profile is now integrated with your Knowledge profiles successfully. ",
    SUCCESSFUL_INTEGRATION_HELP_TEXT:
      "To make any updates to the permissions granted to KN Profiles, please use the LinkedIn settings button on the page.",
    REGRET: "Sorry! Something went wrong.",
    FAILURE_INTEGRATION_MESSAGE: "Your LinkedIn profile could not be integrated with your KN Profile.",
    FAILURE_INTEGRATION_HELP_TEXT: "Please try again in a few mins.",
    TEXTAREA_PLACEHOLDER:
      "Can you please state the reason why you do not want LinkedIn integration on your KN profile.",
    REASON_FOR_REMOVAL: "Reason for Removal ?",
    REMOVAL_WARNING: "Are you sure you want to remove LinkedIn Access?",
    REMOVE_ACCESS: "Remove Access",
    NOT_YOU: "Not You?",
    GUIDE_TEXT: {
      header: "Please Note",
      text: [
        "By clicking allow, you give BCG access to your LinkedIn profile for up to one year.",
        "You can remove this access anytime by clicking on LinkedIn settings button on Profiles.",
        "If you have opt out from LinkedIn Data, on the LinkedIn platform, it will take upto 24 hours to reflect on your BCG profile.",
        "The information pulled from LInkedIn will be used to showcase relevant fields on your Navigator Profile.",
        "BCG will refresh the information from your LinkedIn profile every 24 hours.",
        "By providing your consent, you acknowledge the accuracy of your data."
      ]
    },
    PRIVACY_POLICY: "Privacy Policy",
    AUTH_WINDOW_HEIGHT: "600",
    AUTH_WINDOW_WIDTH: "600",
    DATA_SYNC_COMPLETE: "DSC",
    CANCELLED: "CANCELLED",
    ERROR: "ERROR",
    STATUS_API_TIMEOUT: "2000",
    LINKEDIN_REDIRECT_WARNING: "You will now be redirected to LinkedIn site for authorization.",
    RENEW_CONSENT_HEADER: "Your KN Profile Access to Linkedin Profile will expire in",
    REMIND_ME_LATER: "Remind me Later",
    SAVE_SKIP_COUNT: "skip-count",
    TIME_EXPIRATION: "time-expiration",
    LINKED_IN_HELP_INFO: "If you opted out on LinkedIn's website, it will take 24 hours to reflect on your Profile.",
    SKIP: "skip",
    SKIPS: "skips"
  },
  HOW_CAN_I_BE_MORE_FINDABLE: {
    HEADING: "Profile keyword accuracy is the key to Expert Search findability",
    SUB_HEADING: "Add relevant keywords to your profiles to boost your visibility. ",
    LI_HEADING: "Update your Pre-BCG Experience in ",
    OR: "or",
    LI_SUBHEADING: "Changes you make will reflect back on your Profile",
    WORKDAY: "Workday ",
    LINKEDIN: " LinkedIn",
    LIKE_BUTTON: "GREAT - LET ME MAKE THESE UPDATES!",
    HAVE_A_QUESTION: "I HAVE A QUESTION",
    SUBJECT: "Discover Advanced Strategies for Your BCG Profile – Support Awaits",
    MAIL_ID: "bostonconsultinggroup@service-now.com",
    HOW_TO: (
      <>
        <strong>How?</strong> Select the Edit button to update
      </>
    ),

    CONTENT: [
      {
        heading: "Update your Profile Summary",
        subHeading: "Outline topics, skills and pre-BCG experience"
      },
      {
        heading: "Upload your current bio or resume",
        subHeading: "A current bio should be no more than 6 months old.",
        content: (
          <>
            <p>
              To ensure your resume in a correct format &nbsp;
              <a
                style={{ textDecoration: "underline" }}
                href="https://bcgcloud.sharepoint.com/:p:/s/KDChangeandComms/Ef-olaMoKUtLp6L56BW64CkBvMJnx_erza-cIUBv2jwNvQ?e=PtzoSW"
                target="_blank"
              >
                Download Proposal Bio/Resume Template
              </a>
            </p>
          </>
        )
      },
      {
        heading: "Update Pre-BCG Experience in WorkDay",
        subHeading: "These updates will sync back to Profiles"
      }
    ]
  },
  CVBIO_EXCEL_FIELDSMAP: {
    preferredFirstName: "First Name",
    preferredLastName: "Last Name",
    cohort: "Cohort",
    jobTitle: "Job Title",
    primaryWorkEmailAddress: "Primary Work Email"
  },
  FILTER_BAR: {
    CASE_TYPE: {
      MULTISELECT: true,
      TREE_STRUCTURE: false,
      name: "Case Type",
      QUERY_PARAM: "caseType",
      SORT_ORDER: 3,
      SINEQUA_PARAMETER_NAME: "Case Type",
      ELASTIC_PARAMETER_NAME: "case_type",
      ADV_SEARCH_PARAM: "C_Type",
      SOURCENAME: "sourcecsv45"
    },
    CASE_INDUSTRY_PA_TOPIC: {
      MULTISELECT: true,
      TREE_STRUCTURE: true,
      FULL_TREE: true,
      name: "Industry",
      QUERY_PARAM: "industryPA",
      SORT_ORDER: 1,
      SINEQUA_PARAMETER_NAME: "Case Industry PA/Topic",
      ELASTIC_PARAMETER_NAME: "industrypatopic",
      ADV_SEARCH_PARAM: "industryleaf",
      SOURCENAME: "sourcetree1"
    },
    CASE_FUNCTIONAL_PA_TOPIC: {
      MULTISELECT: true,
      TREE_STRUCTURE: true,
      FULL_TREE: true,
      name: "Function",
      QUERY_PARAM: "functionalPA",
      SORT_ORDER: 2,
      SINEQUA_PARAMETER_NAME: "Case Functional PA/Topic",
      ELASTIC_PARAMETER_NAME: "functionalpatopic",
      ADV_SEARCH_PARAM: "functionalleaf",
      SOURCENAME: "sourcetree3"
    },
    BCG_REGION_OFFICE: {
      MULTISELECT: true,
      TREE_STRUCTURE: true,
      FULL_TREE: true,
      name: "Region",
      QUERY_PARAM: "bcgRegionOffice",
      ONLY_ADVANCED_SEARCH_FILTER: true,
      SHOW_TOOL_TIP: true,
      SORT_ORDER: 11,
      INFO_TOOLTIP: "Filter by region, country, or office for which the material pertains",
      SINEQUA_PARAMETER_NAME: "BCG Region Office",
      ELASTIC_PARAMETER_NAME: "officetoregion",
      ADV_SEARCH_PARAM: "regionoffice",
      COLUMNNAME: "sourcetree9",
      SOURCENAME: "sourcetree9"
    },
    DATE_OPENED: {
      MULTISELECT: false,
      TREE_STRUCTURE: false,
      FULL_TREE: false,
      name: "Date Range",
      QUERY_PARAM: "dateOpened",
      STATIC_OPTIONS: true,
      field: "dateOpened",
      RELATED_PARAMS: ["startDateOpened", "endDateOpened"],
      showFieldName: true,
      SORT_ORDER: 4,
      SINEQUA_PARAMETER_NAME: "Date Range",
      ELASTIC_PARAMETER_NAME: "date_opened_actual",
      SOURCENAME: "sourcedatetime23"
    },
    VIGNETTES_ONLY: {
      name: "Cases with Vignettes Only",
      QUERY_PARAM: "vignettes",
      CHECKBOX_ONLY_FILTER: true,
      SINEQUA_PARAMETER_NAME: "Vignettes Only",
      ELASTIC_PARAMETER_NAME: "casevignettesstatus"
    }
  },
  MATERIALS_FILTER_BAR: {
    MATERIALTYPE: {
      MULTISELECT: true,
      TREE_STRUCTURE: false,
      FULL_TREE: false,
      name: "Material Type",
      QUERY_PARAM: "materialType",
      SORT_ORDER: 1,
      ONLY_ADVANCED_SEARCH_FILTER: false,
      SINEQUA_PARAMETER_NAME: "Material Type",
      ELASTIC_PARAMETER_NAME: "doc_type",
      ADV_SEARCH_PARAM: "doctype",
      COLUMNNAME: "sourcetree6",
      SOURCENAME: "sourcetree6"
    },
    INDUSTRYPATOPIC: {
      MULTISELECT: true,
      TREE_STRUCTURE: true,
      FULL_TREE: true,
      name: "Industry",
      QUERY_PARAM: "industryPA",
      SORT_ORDER: 2,
      ONLY_ADVANCED_SEARCH_FILTER: false,
      SINEQUA_PARAMETER_NAME: "Industry PA/Topic",
      ELASTIC_PARAMETER_NAME: "industrypatopic",
      ADV_SEARCH_PARAM: "industryleaf",
      SOURCENAME: "sourcetree1",
      AND_OR_TOGGLEABLE: true
    },
    FUNCTIONALPATOPIC: {
      MULTISELECT: true,
      TREE_STRUCTURE: true,
      FULL_TREE: true,
      name: "Function",
      QUERY_PARAM: "functionalPA",
      SORT_ORDER: 3,
      ONLY_ADVANCED_SEARCH_FILTER: false,
      SINEQUA_PARAMETER_NAME: "Functional PA/Topic",
      ELASTIC_PARAMETER_NAME: "functionalpatopic",
      ADV_SEARCH_PARAM: "functionalleaf",
      SOURCENAME: "sourcetree3",
      AND_OR_TOGGLEABLE: true
    },
    SUBJECTPAS: {
      MULTISELECT: true,
      TREE_STRUCTURE: true,
      FULL_TREE: true,
      name: "Subject Area",
      QUERY_PARAM: "subjectPA",
      SORT_ORDER: 4,
      ONLY_ADVANCED_SEARCH_FILTER: false,
      SINEQUA_PARAMETER_NAME: "Subject PAs",
      ELASTIC_PARAMETER_NAME: "subjectpatopic",
      ADV_SEARCH_PARAM: "subjectleaf",
      SOURCENAME: "sourcetree8",
      AND_OR_TOGGLEABLE: true
    },
    DOCUPDATEDDATERANGE: {
      MULTISELECT: false,
      TREE_STRUCTURE: false,
      FULL_TREE: false,
      name: "Date Revised",
      QUERY_PARAM: "dateRevised",
      STATIC_OPTIONS: true,
      field: "dateRevised",
      RELATED_PARAMS: ["startDateRevised", "endDateRevised"],
      SORT_ORDER: 5,
      ONLY_ADVANCED_SEARCH_FILTER: false,
      options: [
        { display: "Past Five Years" },
        { display: "Past Two Years" },
        { display: "Past Year" },
        {
          display: "Past 6 Months",
          name: "Past 6 Month"
        },
        { display: "Past 90 Days" },
        { display: "Past 30 Days" }
      ],
      SINEQUA_PARAMETER_NAME: "DOC Updated Date Range",
      ELASTIC_PARAMETER_NAME: "ssdate",
      showFieldName: true,
      SOURCENAME: "sourcedatetime12",
      DATE_RANGE: true
    },
    PA_RECOMMENDED_ONLY: {
      name: "PA Recommended Only",
      QUERY_PARAM: "recommendedBy",
      CHECKBOX_ONLY_FILTER: true,
      SOURCENAME: "sourcecsv14",
      QUERYVALUEFIELD: "name",
      ELASTIC_PARAMETER_NAME: "isrecommendedbypa"
    }
  }
};

export default LABEL;
