const MONTHSTRING = {
  0: { short: "Jan", full: "January" },
  1: { short: "Feb", full: "February" },
  2: { short: "Mar", full: "March" },
  3: { short: "Apr", full: "April" },
  4: { short: "May", full: "May" },
  5: { short: "Jun", full: "June" },
  6: { short: "Jul", full: "July" },
  7: { short: "Aug", full: "August" },
  8: { short: "Sep", full: "September" },
  9: { short: "Oct", full: "October" },
  10: { short: "Nov", full: "November" },
  11: { short: "Dec", full: "December" }
};

const MONTHDIGIT = {
  0: "01",
  1: "02",
  2: "03",
  3: "04",
  4: "05",
  5: "06",
  6: "07",
  7: "08",
  8: "09",
  9: "10",
  10: "11",
  11: "12"
};

const PROFICIENCYSCORES = {
  none: 0,
  beginner: 1,
  intermediate: 2,
  advanced: 3,
  fluent: 4,
  native: 5
};

const LOCATIONOPTIONS = [
  {
    key: 0,
    value: "Select",
    text: "Select"
  },
  {
    key: 0,
    value: "BCG",
    text: "BCG"
  },
  {
    key: 1,
    value: "External",
    text: "External"
  }
];

export { MONTHDIGIT, MONTHSTRING, PROFICIENCYSCORES, LOCATIONOPTIONS };
