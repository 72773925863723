/* eslint-disable indent */
import { isEmptyOrNull } from "helpers/utils";
import { forEach } from "lodash";
import moment from "moment";
import stripHtml from "string-strip-html";

// fuction to get the search data.
const getSearchFilterDataMaterials = (allFilterData, searchKeyword) => {
  const keyword = searchKeyword.toLowerCase().trim();
  const searchFilterData = allFilterData?.filter((item) => {
    return (
      item.materialDesc?.toLowerCase().indexOf(keyword) >= 0 ||
      item.title.toLowerCase().indexOf(keyword) >= 0 ||
      item.fileName.toLowerCase().indexOf(keyword) >= 0 ||
      item.associatedBundles?.some((bundle) => {
        return bundle.title.toLowerCase().indexOf(keyword) >= 0;
      }) ||
      item.allAuthors?.some((auther) => {
        const fullName = `${auther.firstName} ${auther.lastName}`;
        return (
          fullName.toLowerCase().indexOf(keyword) >= 0 ||
          auther.firstName.toLowerCase().indexOf(keyword) >= 0 ||
          auther.lastName.toLowerCase().indexOf(keyword) >= 0
        );
      }) ||
      item.industryAllPAs?.some((industryPA) => {
        return industryPA.topicNameAlias.toLowerCase().indexOf(keyword) >= 0;
      }) ||
      item.functionalAllPAs?.some((functionPA) => {
        return functionPA.topicNameAlias.toLowerCase().indexOf(keyword) >= 0;
      }) ||
      item.allSubjects?.some((subject) => {
        return subject.subjectName.toLowerCase().indexOf(keyword) >= 0;
      })
    );
  });

  return searchFilterData;
};

const getSearchFilterDataCases = (allFilterData, searchKeyword) => {
  const keyword = searchKeyword.toLowerCase().trim();
  const searchFilterData = allFilterData?.filter((item) => {
    const desciption = stripHtml(item.description).result;
    return (
      item.caseVignettes?.some((vignette) => {
        return vignette.fileName.toLowerCase().indexOf(keyword) >= 0;
      }) ||
      item.allCaseTeams?.some((member) => {
        const fullName = `${member.firstName} ${member.lastName}`;
        return (
          fullName.toLowerCase().indexOf(keyword) >= 0 ||
          member.firstName.toLowerCase().indexOf(keyword) >= 0 ||
          member.lastName.toLowerCase().indexOf(keyword) >= 0
        );
      }) ||
      desciption.toLowerCase().indexOf(keyword) >= 0 ||
      item.shortDescription.toLowerCase().indexOf(keyword) >= 0 ||
      item.caseNumber.toLowerCase().indexOf(keyword) >= 0 ||
      item.industryAllPAs?.some((industryPA) => {
        return industryPA.topicNameAlias.toLowerCase().indexOf(keyword) >= 0;
      }) ||
      item.functionalAllPAs?.some((functionPA) => {
        return functionPA.topicNameAlias.toLowerCase().indexOf(keyword) >= 0;
      })
    );
  });

  return searchFilterData;
};

// get filter data by industry tag id
const getIndustryFilterData = (allFilterData, industryFilter) => {
  const industryFilterData = allFilterData?.filter((item) => {
    return item?.industrypas?.some((industryPA) => {
      if (industryFilter.length > 0) return industryFilter.includes(industryPA.topic_id);
      return false;
    });
  });
  return industryFilterData;
};

// get filter data by functional tag id
const getFunctionFilterData = (allFilterData, functionFilter) => {
  const functionFilterData = allFilterData?.filter((item) => {
    return item?.functionalpas?.some((functionPA) => {
      if (functionFilter.length > 0) return functionFilter.includes(functionPA.topic_id);
      return false;
    });
  });
  return functionFilterData;
};

// get filter data by subject area id
const getSubjectFilterData = (allFilterData, subjectFilter) => {
  const subjectFilterData = allFilterData?.filter((item) => {
    return item.allsubjects.some((subject) => {
      if (subjectFilter.length > 0) return subjectFilter.includes(subject.subject_id);
      return false;
    });
  });
  return subjectFilterData;
};

// get filter data by case type
const getCaseTypeFilterData = (allFilterData, caseTypeFilter) => {
  const lowerCaseCaseTypes = caseTypeFilter.map((caseType) => caseType.toLowerCase());
  const caseTypeFilterDate = allFilterData?.filter((item) => {
    if (caseTypeFilter.length > 0) return lowerCaseCaseTypes.includes(item.caseType.toLowerCase());
    return false;
  });
  return caseTypeFilterDate;
};

// get filter data by material type
const getMaterialTypeFilterData = (allFilterData, materialTypeFilter) => {
  const lowerCaseMaterialTypes = materialTypeFilter.map((caseType) => caseType.toLowerCase());
  const materialTypeFilterDate = allFilterData?.filter((item) => {
    if (materialTypeFilter.length > 0) return lowerCaseMaterialTypes.includes(item.docType.toLowerCase());
    return false;
  });
  return materialTypeFilterDate;
};

const getDateFilterDataCases = (allFilterData, date) => {
  const dateFilterData = allFilterData.filter((item) => {
    const today = moment();
    const caseOpenDate = moment(item.caseOpenDate).format("YYYY-MM-DD");
    switch (date) {
      case 1:
        return today.diff(caseOpenDate, "years") < 5;
      case 2:
        return today.diff(caseOpenDate, "years") < 2;
      case 3:
        return today.diff(caseOpenDate, "years") < 1;
      case 4:
        return today.diff(caseOpenDate, "days") <= 90;
      case 5:
        return today.diff(caseOpenDate, "days") <= 30;
      default:
        return true;
    }
  });
  return dateFilterData;
};

const getDateFilterDataMaterials = (allFilterData, date) => {
  const dateFilterData = allFilterData.filter((item) => {
    const today = moment();
    const dateRevised = moment(item.dateRevised).format("YYYY-MM-DD");
    switch (date) {
      case 1:
        return today.diff(dateRevised, "years") < 5;
      case 2:
        return today.diff(dateRevised, "years") < 2;
      case 3:
        return today.diff(dateRevised, "years") < 1;
      case 4:
        return today.diff(dateRevised, "days") <= 90;
      case 5:
        return today.diff(dateRevised, "days") <= 30;
      case 6:
        return today.diff(dateRevised, "months") < 6;
      default:
        return true;
    }
  });
  return dateFilterData;
};

const getPARecommendedOnlyMaterials = (allFilterData, isPARecommended) => {
  if (isPARecommended) {
    const paRecommendedData = allFilterData.filter((item) => item.paRecommended === true);
    return paRecommendedData;
  }
  return allFilterData;
};

// sort filter options by number of items in descending order and alphabetically.

const sortFilterOptions = (a, b) => {
  if (b.sortOrder - a.sortOrder === 0) {
    if (a.textForAdobeAnalytics < b.textForAdobeAnalytics) return -1;
    if (a.textForAdobeAnalytics > b.textForAdobeAnalytics) return 1;
    return 0;
  }
  return b.sortOrder - a.sortOrder;
};

const getUniqueIndustryAllPAs = (documentsData) => {
  let industryAllPAsForAll = [];

  documentsData?.forEach((document) => {
    if (!Array.isArray(document?.industrypas)) return; // Ensure industrypas is an array before proceeding

    const industryAllPAsPerDocument = document.industrypas.reduce((accumulator, currentValue) => {
      const numerOfItems = getIndustryFilterData(documentsData, [currentValue.topic_id]).length;
      const topic = {
        key: currentValue.topic_id,
        text: `${currentValue.topic_name_alias} (${numerOfItems})`,
        textForAdobeAnalytics: currentValue.topic_name_alias,
        value: currentValue.topic_id,
        sortOrder: numerOfItems
      };
      accumulator.push(topic);
      return accumulator;
    }, []);

    if (industryAllPAsPerDocument.length > 0) {
      industryAllPAsForAll.push(...industryAllPAsPerDocument);
    }
  });

  const uniqueIndustryPAsData = industryAllPAsForAll.reduce((accumulator, currentValue) => {
    if (!accumulator.some((acc) => acc.key === currentValue.key)) {
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);

  return uniqueIndustryPAsData.sort(sortFilterOptions);
};

const getUniqueFunctionalAllPAs = (documentsData) => {
  let functionalAllPAsForAllMaterials = [];
  documentsData?.forEach((document) => {
    if (!document?.functionalpas) {
      // Skip this document if functionalpas is not present
      return;
    }
    if (!Array.isArray(document?.functionalpas)) return; // Ensure functionalpas is an array before proceeding

    const functionalAllPAsPerMaterial = document?.functionalpas?.reduce((accumulator, currentValue) => {
      const numerOfItems = getFunctionFilterData(documentsData, [currentValue.topic_id]).length;
      const topic = {
        key: currentValue.topic_id,
        text: `${currentValue.topic_name_alias} (${numerOfItems})`,
        textForAdobeAnalytics: currentValue.topic_name_alias,
        value: currentValue.topic_id,
        sortOrder: numerOfItems
      };
      accumulator.push(topic);
      return accumulator;
    }, []);
    functionalAllPAsForAllMaterials = [...functionalAllPAsForAllMaterials, ...functionalAllPAsPerMaterial];
  });

  const uniqueFunctionalPAsData = functionalAllPAsForAllMaterials.reduce((accumulator, currentValue) => {
    if (!accumulator.find((acc) => acc.key === currentValue.key)) {
      accumulator.push(currentValue);
    }
    return accumulator.sort(sortFilterOptions);
  }, []);
  return uniqueFunctionalPAsData;
};

const getUniqueSubjectAreas = (documentsData) => {
  let subjectAreasForAllMaterials = [];
  documentsData?.forEach((document) => {
    const subjectAreasPerMaterial = document.allsubjects.reduce((accumulator, currentValue) => {
      const numerOfItems = getSubjectFilterData(documentsData, [currentValue.subject_id]).length;
      const subject = {
        key: currentValue.subject_id,
        text: `${currentValue.subject_name} (${numerOfItems})`,
        textForAdobeAnalytics: currentValue.subject_name,
        value: currentValue.subject_id,
        sortOrder: numerOfItems
      };
      accumulator.push(subject);
      return accumulator;
    }, []);
    subjectAreasForAllMaterials = [...subjectAreasForAllMaterials, ...subjectAreasPerMaterial];
  });

  const uniqueSubjectAreasData = subjectAreasForAllMaterials.reduce((accumulator, currentValue) => {
    if (!accumulator.find((acc) => acc.key === currentValue.key)) {
      accumulator.push(currentValue);
    }
    return accumulator.sort(sortFilterOptions);
  }, []);
  return uniqueSubjectAreasData;
};

const getCaseTypes = (documentsData) => {
  const caseTypeRefiner = documentsData?.refiners?.filter((item) => item?.parameterName === "Case Type");

  const caseTypes = !isEmptyOrNull(caseTypeRefiner)
    ? caseTypeRefiner[0]?.refinerValues?.reduce((accumulator, currentValue) => {
        if (
          !accumulator.find((acc) => acc?.key?.toLowerCase() === currentValue?.display.toLowerCase()) &&
          currentValue.display
        )
          accumulator.push({
            key: currentValue.display,
            text: `${currentValue.display} (${currentValue.count})`,
            textForAdobeAnalytics: currentValue.display,
            value: currentValue.display,
            sortOrder: currentValue.count
          });
        return accumulator.sort(sortFilterOptions);
      }, [])
    : [];

  return caseTypes;
};

const getDateRange = (documentsData) => {
  const result = [{ key: 0, value: 0, text: "All" }];
  const dateRangeRefiner = documentsData?.refiners?.filter((item) => item?.parameterName === "Date Range");
  if (!isEmptyOrNull(dateRangeRefiner)) {
    dateRangeRefiner[0]?.refinerValues.map((item, i) => {
      if (item?.count > 0) {
        result.push({ key: i + 1, value: i + 1, text: item?.display });
      }
      return item;
    });
  }

  return result;
};
const getDateRangeCases = (documentsData) => {
  const result = [{ key: 0, value: 0, text: "All" }];
  const dateRangeRefiner = documentsData?.filter((item) => item?.parameterName === "Date Range");
  if (!isEmptyOrNull(dateRangeRefiner)) {
    dateRangeRefiner[0]?.refinerValues.map((item, i) => {
      if (item?.count > 0) {
        result.push({ key: i + 1, value: i + 1, text: item?.display });
      }
      return item;
    });
  }

  return result;
};

const getDateRevisedMaterials = (documentsData) => {
  const result = [{ key: 0, value: 0, text: "All" }];
  const dateRangeRefiner = documentsData?.filter((item) => item?.parameterName === "DOC Updated Date Range");
  if (!isEmptyOrNull(dateRangeRefiner)) {
    dateRangeRefiner[0]?.refinerValues.map((item, i) => {
      if (item?.count > 0) {
        result.push({ key: i + 1, value: i + 1, text: item?.display });
      }
      return item;
    });
  }
  return result;
};

const getDateRevised = (documentsData) => {
  const result = [{ key: 0, value: 0, text: "All" }];
  const dateRangeRefiner = documentsData?.refiners?.filter(
    (item) => item?.parameterName === "DOC  DOCUMENTREPLACEMENTDATE OR PUBLISHDATE"
  );
  if (!isEmptyOrNull(dateRangeRefiner)) {
    dateRangeRefiner[0]?.refinerValues.map((item, i) => {
      if (item?.count > 0) {
        result.push({ key: i + 1, value: i + 1, text: item?.display });
      }
      return item;
    });
  }

  return result;
};

const getUniqueMaterialTypes = (documentsData) => {
  const materialTypeRefiner = documentsData?.refiners?.filter((item) => item?.parameterName === "Material Type");
  const materialTypes = !isEmptyOrNull(materialTypeRefiner)
    ? materialTypeRefiner[0]?.refinerValues?.reduce((accumulator, currentValue) => {
        if (
          !accumulator.find((acc) => acc?.key?.toLowerCase() === currentValue?.display?.toLowerCase()) &&
          currentValue.display
        )
          accumulator.push({
            key: currentValue.display,
            text: `${currentValue.display} (${currentValue.count})`,
            textForAdobeAnalytics: currentValue.display,
            value: currentValue.display,
            sortOrder: currentValue.count
          });
        return accumulator.sort(sortFilterOptions);
      }, [])
    : [];
  return materialTypes;
};

/**
 * Filter out filters selected from PAs array of object by key
 * @param {*} filters
 * @param {*} uniquePAs
 * @returns Array
 */
const getFilteredObjectForAdobe = (filters, uniquePAs) => {
  let filteredOptions = [];
  forEach(filters, (item) => {
    filteredOptions = filteredOptions.concat(uniquePAs.filter((obj) => obj.value === item));
  });
  return filteredOptions;
};

/**
 * Create text for Adobe Analytics for eVar19 (filter)
 * @param {*} initial
 * @param {*} filteredOptions
 * @returns String
 */
const getStringForAdobe = (initial, filteredOptions) => {
  const texts = [];
  forEach(filteredOptions, (obj) => {
    texts.push(`/${obj.textForAdobeAnalytics || obj.text}/`);
  });
  return initial + texts.join(",");
};

const BREAKPOINTS = {
  XS: {
    MIN: 0,
    MAX: 575
  },
  SM: {
    MIN: 576,
    MAX: 666
  },
  MD: {
    MIN: 667,
    MAX: 1023
  },
  LG: {
    MIN: 1024,
    MAX: 1199
  },
  XL: { MIN: 1200 }
};

/**
 * Check screen size
 * @returns {Boolean}
 */
const isXS = () => {
  return (
    window.matchMedia(`(min-width: ${BREAKPOINTS.XS.MIN}px)`).matches &&
    window.matchMedia(`(max-width: ${BREAKPOINTS.XS.MAX}px)`).matches
  );
};

/**
 * Check screen size
 * @returns {Boolean}
 */
const isSM = () => {
  return (
    window.matchMedia(`(min-width: ${BREAKPOINTS.SM.MIN}px)`).matches &&
    window.matchMedia(`(max-width: ${BREAKPOINTS.SM.MAX}px)`).matches
  );
};

/**
 * Check screen size
 * @returns {Boolean}
 */
const isMD = () => {
  return (
    window.matchMedia(`(min-width: ${BREAKPOINTS.MD.MIN}px)`).matches &&
    window.matchMedia(`(max-width: ${BREAKPOINTS.MD.MAX}px)`).matches
  );
};

const isLG = () => {
  return (
    window.matchMedia(`(min-width: ${BREAKPOINTS.LG.MIN}px)`).matches &&
    window.matchMedia(`(max-width: ${BREAKPOINTS.LG.MAX}px)`).matches
  );
};

/**
@description this function creates copy of an object
@param obj as an object
@returns object copy
*/
const copyObject = (obj) => {
  if (!!obj && typeof obj === "object") {
    return JSON.parse(JSON.stringify(obj));
  }
  return obj;
};

const setDisabledGuid = (record, disabledGuids, disabledTopicName) => {
  const newData = record?.map((guidData) => {
    const newObj = copyObject(guidData);
    newObj.isDisabled =
      !newObj.isSelected &&
      (disabledGuids?.findIndex((data) => data.guid === newObj.term.id) !== -1 ||
        disabledTopicName?.findIndex((data) => data === newObj.term.name) !== -1);
    return newObj;
  });
  return newData;
};

/**
 *
 * @param {date} date1
 * @param {date} date2
 *
 * @returns difference between dates in months
 */
const getDifferenceInMonths = (date1, date2) => {
  let months;
  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months <= 0 ? 0 : months;
};

// Calculate from_n (0-based index) starting position for pagination
const calculateFromN = (resultsFromPage, resultsPerPage) => {
  const currentPage = parseInt(resultsFromPage, 10);
  const perPage = parseInt(resultsPerPage, 10);

  // Calculate from_n (1-based index) and ensure it's not less than 1
  const calculatedFromN = (currentPage - 1) * perPage + 1;

  return Math.max(1, calculatedFromN);
};

export {
  getSearchFilterDataMaterials,
  getSearchFilterDataCases,
  getIndustryFilterData,
  getFunctionFilterData,
  getSubjectFilterData,
  getUniqueFunctionalAllPAs,
  getUniqueIndustryAllPAs,
  getUniqueSubjectAreas,
  getFilteredObjectForAdobe,
  getStringForAdobe,
  isXS,
  isSM,
  isMD,
  isLG,
  sortFilterOptions,
  getCaseTypes,
  getCaseTypeFilterData,
  getMaterialTypeFilterData,
  getUniqueMaterialTypes,
  getDateFilterDataCases,
  getDateFilterDataMaterials,
  getPARecommendedOnlyMaterials,
  setDisabledGuid,
  copyObject,
  getDateRange,
  getDateRangeCases,
  getDifferenceInMonths,
  getDateRevised,
  getDateRevisedMaterials,
  calculateFromN
};
