import { call, put } from "redux-saga/effects";
import { post } from "redux/api";
import { setRecentSSInternalMaterials, setError } from "redux/actions/ssRecentInternalMaterials";

function* handleFetchSSRecentInternalMaterials({ payload }) {
  const { res, error } = yield call(post, payload);
  if (error) {
    yield put(setError(error));
  } else {
    yield put(setRecentSSInternalMaterials(res.data));
  }
}

export default handleFetchSSRecentInternalMaterials;
