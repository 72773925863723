import { call, put } from "redux-saga/effects";
import { post } from "redux/api";
import { setSSMaterials, setSSMaterialsError } from "redux/actions/ssMaterials";

function* handleFetchSSCases({ payload }) {
  const { res, error } = yield call(post, payload);
  if (error) {
    yield put(setSSMaterialsError(error));
  } else {
    yield put(setSSMaterials(res.data));
  }
}

export default handleFetchSSCases;
