import CONFIG from "constants/config";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecentSSInternalMaterials } from "redux/actions/ssRecentInternalMaterials";
import { getRecentSSMaterials } from "redux/actions/ssRecentMaterials";
import { getAllSortedRecentMaterials } from "helpers/utils";

const { apiUrl, xApiKey, elasticConfigMaterial, elasticConfigInternalMaterial } = CONFIG;

const useFetchRecentMaterials = (profile, isErrorApi) => {
  const dispatch = useDispatch();

  const [recentMaterials, setRecentMaterials] = useState({});

  const { ssRecentMaterials } = useSelector((state) => state);
  const { ssRecentMaterialsLoading } = useSelector((state) => state);
  const [materialCount, setMaterialCount] = useState(null);
  const { ssRecentInternalMaterials } = useSelector((state) => state);
  const { ssRecentInternalMaterialsLoading } = useSelector((state) => state);

  const getMaterialData = (consumer, datasource, id) => {
    return {
      endpointUrl: `${apiUrl.enrichURL}genai-enbl-retrieval/v1/search/`,
      // endpointUrl: "https://api-kd-test.integration.smp.bcg.com/genai-enbl-retrieval/v1/search/",
      data: {
        query: "",
        hrid: profile?.hrId,
        data_repository: "elastic",
        method: "bm25",
        from_n: 1,
        top_n: 4,
        doc_level_search: true,
        elastic_cluster: elasticConfigMaterial.clusterType,
        consumer_options: {
          consumer_key: consumer,
          data_source: datasource,
          workspace_id: id
        },
        search_options: {
          filter_clauses: [],
          advancesearch: [],
          facets: [],
          default_sort: true
        }
      },
      config: {
        headers: {
          "x-api-key": `${xApiKey.elaseticCases}`,
          "content-type": "application/json"
        }
      }
    };
  };

  useEffect(() => {
    if (profile?.hrId && !isErrorApi) {
      // external materials
      dispatch(getRecentSSMaterials(getMaterialData("materials", "materials", elasticConfigMaterial.workSpaceId)));
      // internal materials
      dispatch(
        getRecentSSInternalMaterials(getMaterialData("internal", "internal", elasticConfigInternalMaterial.workSpaceId))
      );
    }
  }, [profile?.hrId, dispatch]);
  // Material count without initializing to 0

  useEffect(() => {
    if (recentMaterials.number_of_results) setMaterialCount(recentMaterials.number_of_results.toLocaleString());
    else if (!ssRecentMaterialsLoading && recentMaterials.number_of_results === undefined) setMaterialCount("0");
    else setMaterialCount("--");
  }, [recentMaterials]);

  useEffect(() => {
    setRecentMaterials(getAllSortedRecentMaterials(ssRecentMaterials, ssRecentInternalMaterials));
  }, [ssRecentMaterials, ssRecentInternalMaterials]);

  return {
    recentMaterialsLoading: ssRecentMaterialsLoading || ssRecentInternalMaterialsLoading,
    recentMaterials,
    materialCount
  };
};

export default useFetchRecentMaterials;
